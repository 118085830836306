
import '../scss/base.scss'

/* Project specific Javascript goes here. */

class CAPP {
  constructor() {
  }
}

export const APP = new CAPP()
